export default {
    ru: 'орусча',
    kg: 'кыргызча',
    electronic_enroll: 'Мектепке жана бала бакчага <br> балдарды кабыл алуу боюнча маалыматтык тутум',
    helplines: 'Ишеним телефону',
    operating_mode: 'Иштөө режими: Дш-Жм 09:00-18:00',
    main: 'Башкы бет',
    faq: 'Суроолор/жооптор',
    school: 'Мектеп',
    preschool: 'Бала бакча',
    statistics: 'Статистика',
    regulatory_legal_framework: 'Укуктук-ченемдик база',
    application_apply_school: 'Мектепке өтүнмө берүү',
    application_to_school: 'Мектепке кирүүгө арыз берүү',
    instruction_title: 'Нускама',
    parent_info: 'Баланын ата-энеси/мыйзамдуу өкүлү жөнүндө маалыматтар',
    child_info: 'Бала жөнүндө маалыматтар',
    school_selection: 'Мектепти тандоо',
    preschool_selection: 'Бала бакчаны тандоо',
    application_review: 'Киргизилген маалыматтарды жөнөтүүнүн алдында текшерүү',
    application_step: ':total КАДАМДАН :step',
    applicant_form_hint: '(*) менен белгиленген талаалардын толтурулушу милдеттүү',
    applicant_type_label: 'Сиз баланын кимиси болосуз',
    select_from: 'Тизмеден тандаңыз',
    kdd_status: 'Статус КДД',
    status_kkd_true: 'дети у которых имеются документы',
    status_kkd_false: 'дети у которых отсутствуют документы',
    citizenship: 'Жарандыгы',
    citizen_kg: 'Мен КРнын жаранымын',
    citizen_other: 'Мен башка өлкөнүн жаранымын',
    pin: 'ИЖН (Жеке номуру/ПИН)',
    surname: 'Фамилиясы',
    only_cyrillic: 'Кириллица гана колдонулушу мүмкүн',
    name: 'Аты',
    middle_name: 'Атасынын аты (Отчество)',
    birth_date: 'Туулган күнү',
    male: 'Эркек',
    female: 'Кыз',
    passport_seria: 'Паспорт сериясы',
    passport_number: 'Паспорттун номери',
    passport_number_des: 'Бул тилкеде сиз идентификациялык картанын же паспорттун номерин боштуксуз киргизишиңиз керек.',
    passport_number_example: 'Мисалы: 1234567',
    country_code: 'Өлкөнүн коду',
    document_number: 'Документтин номери',
    phone_number: 'Телефон номери',
    applicant_email: 'Электрондук почтасы',
    // second_applicant_email: 'Энесинин электрондук почтасы',
    privilege_type: 'Жеңилдиктин түрү',
    applicant_privilege_type: 'Ата-энесинин/мыйзамдуу өкүлүнүн жеңилдик түрү',
    personal_data_agreement: 'Мен жеке маалыматтарымдын иштетилүүсүнө макулмун',
    review: 'Көрүү',
    forward: 'Андан ары',
    new_form: 'Жаңы форма',
    child_gender: 'Баланын жынысы',
    birth_certificate_seria: 'Туулгандыгы тууралуу күбөлүктүн сериясы',
    birth_certificate_number: 'Туулгандыгы тууралуу күбөлүктүн номери',
    delete_child: 'Баланы өчүрүү',
    add_child: 'Баланы кошуу',
    add_child_by_kdd: 'БИБК боюнча кошуу',
    language_label: 'Окутуунун каалануучу тили',
    region_city: 'Областы/Шаары',
    organization_selection: 'Уюмду тандоо',
    list_empty: 'Тизме бош',
    not_found: 'Табылган жок',
    review_title: 'Тапшыруудан мурун аяктаган маалыматты карап чыгыңыз',
    review_hint: 'Сураныч, маалыматыңызды кылдат текшериңиз. Эгер кандайдыр бир ката тапсаңыз, анда аны оңдоңуз.',
    applicant_full_name: 'Арыз берүүчүнүн толук аты-жөнү',
    passport_seria_and_number: 'Паспортун cериясы жана номери',
    country_code_and_document: 'Өлкө коду жана документ',
    update_data: 'Маалыматты өзгөртүү',
    child_full_name: 'Баланын толук аты-жөнү',
    child_birth_date: 'Баланын туулган күнү',
    birth_certificate_seria_and_number: 'Туулгандыгы тууралуу күбөлүктүн сериясы жана номери',
    select_school_preschool: 'Мектепти/бала бакчаны тандоо',
    school_preschool: 'Мектеп/бала бакча',
    verify_submit: 'Ооба бардыгы туура. Арыз бер',
    application_success_title: 'Сиздин өтүнмөңүз ийгиликтүү аткарылды!',
    application_success_content: 'Сиз башкы бетте жайгашкан «АРЫЗДЫН СТАТУСУН ТЕКШЕРҮҮ» баскычын басып, андан кийин баланын ПИН номерин көрсөтүп, «Текшерүү» баскычын бассаңыз сиздин өтүнмөнүн статусун текшере аласыз.',
    to_main: 'Башкы',
    write_us: 'Бизге жаз',
    write_us_des: 'Эгерде сизде кандайдыр бир суроолор болсо же сунуштарыңызды жана даттанууларды билдиргиңиз келсе, төмөндөгү пикир формасы аркылуу биз менен байланышыңыз.',
    service_error_title: 'Көңүл бургула! Учурда системада пландалбаган техникалык иштер жүрүп жатат',
    // service_error_des: 'Администратор системаны калыбына келтирүү үстүндө иштеп жатат. Сураныч, 15-20 мүнөттөн кийин кайра аракет кылыңыз!',
    service_error_des: 'Сураныч, 15-20 мүнөттөн кийин кайра аракет кылыңыз!',
    service_error_apologize: 'Келтирилген ыңгайсыздыктар үчүн кечирим сурайбыз.',
    loading_text: 'Маалыматтарды иштетүү жүрүп жатат',
    reset_account: 'Каттоо эсебин калыбына келтирүү',
    email_success_des: 'Сиздин электрондук почтаңызга активдештирүү коду менен билдирүү жөнөтүлдү:',
    reset_account_des: 'Арыз берүүдө колдонгон паспортуңуздун фамилиясын, сериясын жана номерин көрсөтүңүз',
    cancel: 'Жокко чыгаруу',
    email: 'Электрондук почтасы',
    authentication: 'Авторизация',
    username: 'Сиздин логиниңиз',
    password: 'Сыр сөз',
    login: 'Кирүү',
    forgot_password: 'Сыр сөзүңүздү унуттуңузбу?',
    forgot_email: 'Электрондук почтаны унутуп калдыңызбы?',
    enter_username: 'Логинди киргизиңиз',
    enter_password: 'Сырсөздү киргизиңиз',
    password_error: 'Сырсөздү латын тамгалар менен киргизиниз',
    verify_identity: 'Каттоо эсебиңизди калыбына келтиришибиз үчүн өзүңүздүн инсандыгыңызды ырасташыңыз керек болот.',
    send_email: 'Электрондук кат жөнөтүү',
    organization: 'Мекеме',
    group: 'Группа',
    short_term_group: 'Кыска мөөнөттүү (3 сааттык) мектепке чейинки билим берүү',
    privilege_file: '"Жеңилдиктер" документи',
    kdd_file: 'БИБК чечими',
    legal_representative_file: 'Документ "Мыйзамдуу өкүл"',
    application_apply_preschool: 'Бала бакчага өтүнмө берүү',
    application_apply_school_new: '"Мектепке кабыл алуу"<br>жаңы сайтына өтүү',
    application_to_preschool: 'Бала бакчага өтүнмө берүү',
    application_check: 'Арыздын статусун текшерүү',
    application_check_school: 'Мектеп арызынын абалын текшерүү',
    application_check_preschool: 'Бала бакчага арыздын абалын текшерүү',
    organization_check: 'Мектепти издөө',
    application_check_instruction: 'Бул кызматтын жардамы менен сиз арыздын статусун, баланын ИЖН номерин жазып, «мен робот эмесмин» пунктун быйтыкча менен белгилеп жана «Текшерүү» кнопкасын басып текшере аласыз.',
    organization_check_instruction: 'Бул кызматтын жардамы менен атаңыздын/апаңыздын ПИН-кодун киргизип, “Текшерүү” баскычын басуу менен кайсы мектепке таандык экениңизди биле аласыз.',
    child_pin: 'Баланын ИЖНи (ПИН)',
    check: 'Текшерүү',
    back: 'Артка',
    birth_certificate: 'Туулгандыгы тууралуу күбөлүк',
    birth_certificate_des: 'Бул тилкеге ​​Кыргыз Республикасынын жаранынын өздүгүн тастыктоочу негизги документтерде тапса боло турган жеке идентификациялык номерди (ПИН) киргизүү керек.',
    birth_certificate_example: 'Мисалы: 20101199100000',
    preschool_faq_instruction: 'Бул жерден сиз бала бакчага тапшырууда суроолоруңузга жооп ала аласыз',
    school_faq_instruction: 'Бул жерден мектепке тапшырууда суроолоруңузга жооп ала аласыз',
    faq_instruction: 'Бул жерден сиз суроолоруңузга жооп ала аласыз',
    faq_not_found: 'Сурооңузга жооп таба алдыңызбы?',
    faq_form_instruction: 'Төмөнкү форманы колдонуу менен бизге кайрылыңыз',
    your_name: 'Атыңыз',
    your_email: 'Сиздин Е-mail',
    complaint: 'Даттануу',
    question: 'Суроо',
    appeal: 'Кайрылуу',
    message_text: 'Билдирүүнүн тексти',
    send: 'Жөнөтүү',
    relationship_check: 'Туугандыгын текшерүү',
    relationship_check_instruction: 'Бул кызматтын жардамы менен сиз, баланын ИЖНин (ПИН) жана ата/эненин ИЖНин (ПИН) көрсөтүп жана «Текшерүү» кнопкасын басып, баланын ата-энеси менен байланыштырылышын текшере аласыз.',
    parent_pin: 'Атасынын/энесинин ИЖНи (ПИН)',
    id_card_des: 'Бул тилкеге ​​Кыргыз Республикасынын жаранынын өздүгүн тастыктоочу негизги документтерде тапса боло турган жеке идентификациялык номерди (ПИН) киргизүү керек.',
    id_card_example: 'Мисалы: 20101199100000',
    id_card: 'ID карта (2017)',
    passport: 'Паспорт (2004)',
    applicant_is_parent: 'Арыз берүүчү бул баланын :parent, сиз кайрылсаңыз болот.',
    applicant_is_not_parent: 'Арыз берүүчү бул баланын :parent эмес.',
    is_father: 'атасы',
    is_mother: 'апасы',
    note_title: 'Маанилүү!',
    relationship_check_note: 'Туугандыгы тууралуу маалыматтар электрондук түрдө, 2015-жылдан тартып түзүлөт',
    countdown_heading: 'Мектепке кабыл алуунун этаптары:',
    days: 'күн',
    hours: 'саат',
    minutes: 'мүнөт',
    seconds: 'секунд',
    by_residence_countdown: '1-этапка өтүнмө башталганга чейин калды:',
    outside_residence_countdown: '2-этапка өтүнмө башталганга чейин калды:',
    application_not_found_text: 'Көрсөтүлгөн ЖИН боюнча арыз жок!',
    application_rejected_text: 'Арызыңыз четке кагылды!',
    application_waiting_text: 'Сиздин электрондук кезегиңиз',
    application_enroll_text: 'Сиздин балаңыз кабыл алынды',
    available_organizations_map: 'Жеткиликтүү мекемелердин картасы',
    login_parent_account: 'Ата-эненин жеке кабинетине кирүү',
    login_account: 'Жеке кабинетине кирүү',
    footer_contact_text: 'Коррупциянын көрүнүштөрү тууралуу маалымат алсаңыз, төмөнкү байланыш телефондоруна кайрылыңыз: 1222',
    technical_support: 'Техникалык колдоо',
    technical_support_phone: 'Техникалык колдоо номери',
    work_schedule: 'Иш убактысы: Дүйшөмбү-Жума саат 9:00дөн 18:00гө чейин',
    lunch_break: 'Түшкү тыныгуу: 12:30дөн 13:30гө чейин',
    feedback: 'Кайра байланыш',
    education_and_science_ministry: 'Кыргыз Республикасынын Билим берүү жана илим министрлиги',
    developer: 'Иштеп чыгуучу',
    infocom: '«Инфоком» мамлекеттик ишканасы',
    how_it_works: {
        title: 'Бул кандай иштейт?',
        des: 'Балдарды кабыл алуу үч этап менен жүргүзүлөт',
        filling_application: {
            title: 'Өтүнмө берүү',
            des: 'Баланын ата-энеси/мыйзамдуу өкүлү сайттан өтүнмөнүн формасын толтурат'
        },
        consideration_application: {
            title: 'Өтүнмөнү кароо',
            des: 'Мамлекеттик жана муниципалдык жалпы билим берүүчү уюмдун жетекчиси Сиздин өтүнмөңүздү текшерет'
        },
        enrollment: {
            title: 'Баланы кабыл алуу',
            des: 'Өтүнмөнү кабыл алгандан кийин баланы кабыл алуу ишке ашырылат'
        },
        detailed_instructions: 'Толук нускама'
    },
    logout: 'Чыгуу',
    regulatory_legal_framework_hint: 'Бул жерде укуктук-ченемдик база менен таанышсаңыз болот',
    instruction: {
        preschool: {
            title: 'Ата-энелер/мыйзамдуу өкүлдөр үчүн мектепке чейинки билим берүү уюмдарына кабыл алуу үчүн арыз берүүгө нускама\n',
            how_to_apply: 'Кантип колдонуу керек?',
            fill_in: 'Колдонуу үчүн, төмөнкү маалыматты толтуруңуз:',
            relation_type: 'Балага ким экениңизди көрсөтүңүз?',
            father: 'Ата;',
            mother: 'Эне;',
            legal_representative: 'Мыйзамдуу өкүл (бул пунктту тандагандан кийин тиешелүү документти тиркөө керек).',
            applicant_info: 'Баланын ата-энеси/мыйзамдуу өкүлү жөнүндө маалымат:',
            nationality: 'Жарандык;',
            passport_info: 'Идентификациялык жеке номери (ИЖН), аты-жөнү, паспорттук маалыматтары (сериясы, номери);',
            passport_front: 'Паспорттун алдыңкы бети',
            passport_back: 'Паспорттун арткы бети',
            phone: 'Уюлдук телефондун номери;',
            email: 'Электрондук почтасы;',
            // extra_email: 'Кошумча электрондук почта (Электрондук почталар логин, паролду калыбына келтирүү үчүн колдонулат);',
            telegram_phone: 'Telegram номерин туташтыруу (логин, паролду калыбына келтирүүнүн альтернативалуу варианты);',
            privilege: 'Артыкчылыгы (льгота) Бар/Жок.',
            children_info: 'Бала тууралуу маалымат:',
            child_info: 'Идентификациялык жеке номери (ИЖН), толук аты-жөнү, туулган жылы, баланын жынысы;',
            birth_certificate_info: 'Туулгандыгы тууралуу күбөлүктүн сериясы, номери;',
            birth_certificate_back: 'Туулгандыгы тууралуу күбөлүктүн арты',
            child_privilege: 'Билим алуу үчүн балдарга артыкчылыгы (льгота) бар/жок.',
            school_selection: 'Мектепке чейинки билим берүү уюмун тандоо:',
            language: 'Окутуунун тили;',
            by_address: 'Арыз берүүчү каалаган бала бакчаны тандай алат.',
            preview: 'Толтурулган форманы жөнөтүү алдында маалыматты карап чыгуу:',
            fill_in_check: 'Өтүнмөнү берүүдөн мурун арызда толтурулган маалыматтардын тууралыгын текшерүү зарыл.',
            completed_description: 'Арызыңызды ырастагандан кийин, Сиздин электрондук почтаңызга мектепке кирүү үчүн арыз ийгиликтүү кабыл алынгандыгы тууралуу билдирүү жөнөтүлөт.',
            start_apply: 'Төмөнкү маалымат менен онлайн арызды толтуруңуз',
        },
        school: {
            title: 'Ата-энелер/мыйзамдуу өкүлдөр үчүн мектепке балдарды кабыл алууга арыз берүүнүн нускамасы',
            how_to_apply: 'Кантип колдонуу керек?',
            fill_in: 'Колдонуу үчүн, төмөнкү маалыматты толтуруңуз:',
            relation_type: 'Балага ким экениңизди көрсөтүңүз?',
            father: 'Ата;',
            mother: 'Эне;',
            legal_representative: 'Мыйзамдуу өкүл (бул пунктту тандагандан кийин тиешелүү документти тиркөө керек).',
            applicant_info: 'Баланын ата-энеси/мыйзамдуу өкүлү жөнүндө маалымат:',
            nationality: 'Жарандык;',
            passport_info: 'Идентификациялык жеке номери (ИЖН), аты-жөнү, паспорттук маалыматтары (сериясы, номери);',
            passport_front: 'Паспорттун алдыңкы бети',
            passport_back: 'Паспорттун арткы бети',
            phone: 'Уюлдук телефондун номери;',
            email: 'Электрондук почтасы;',
            // extra_email: 'Кошумча электрондук почта (Электрондук почталар логин, паролду калыбына келтирүү үчүн колдонулат);',
            telegram_phone: 'Telegram номерин туташтыруу (логин, паролду калыбына келтирүүнүн альтернативалуу варианты);',
            privilege: 'Артыкчылыгы (льгота) Бар/Жок.',
            children_info: 'Бала тууралуу маалымат:',
            child_info: 'Идентификациялык жеке номери (ИЖН), толук аты-жөнү, туулган жылы, баланын жынысы;',
            by_first_lap: 'Биринчи этап үчүн:',
            birth_certificate_info: 'Туулгандыгы тууралуу күбөлүктүн сериясы, номери;',
            birth_certificate_back: 'Туулгандыгы тууралуу күбөлүктүн арты',
            child_privilege: 'Билим алуу үчүн балдарга жеңилдик (льгота) бар/жок.',
            school_selection: 'Мектепти тандоо:',
            language: 'Окутуунун каалаган тили;',
            by_address: 'Ата-эненин катталган дареги боюнча система автоматтык түрдө тиешелүү мектепти көрсөтөт.',
            by_second_lap: 'Экинчи этап үчүн:',
            region_city: 'Область/шаар',
            applicant_can_select: 'Арыз ээси каалаган бош орун бар мектепти тандай алат.',
            preview: 'Толтурулган форманы жөнөтүү алдында маалыматты карап чыгуу:',
            fill_in_check: 'Өтүнмөнү берүүдөн мурун арызда толтурулган маалыматтардын тууралыгын текшерүү зарыл.',
            completed_description: '•\tАрызыңызды ырастагандан кийин, Сиздин электрондук почтаңызга мектепке кирүү үчүн арыз ийгиликтүү кабыл алынгандыгы тууралуу билдирүү жөнөтүлөт.',
            start_apply: 'Төмөнкү маалымат менен онлайн арызды толтуруңуз',
        }
    },

    application_info: 'Арыздын маалыматтары',
    applicant_info: 'Ата-энесинин маалыматтары',
    children_info: 'Баланын маалыматтары',
    change_school: 'Мектепти алмаштыруу',
    change_preschool: 'Бала бакчаны алмаштыруу',
    enroll: 'Каттоо',
    reject: 'Четке кагуу',
    archive: 'Архивдөө',
    drop: 'Чыгарып салуу',
    application_type: 'Түрү',
    application_status: 'Статус',
    application_comment: 'Комментарий',
    education_language: 'Окутуу тили',
    school_or_preschool: 'Мектеп/Бала бакча',
    archived: 'Архивделген',
    yes: 'Ооба',
    no: 'Жок',
    archived_comment: 'Архивдөө белгиси',
    to_school: 'Мектепке',
    to_preschool: 'Бала бакчага',
    applicant_pin: 'Ата-энесинин/мыйзамдуу өкүлүнүн ИЖНи',
    applicant_fullname: 'Ата-энесинин/мыйзамдуу өкүлүнүн ФААсы',
    child_privilege_type: 'Баланын жеңилдик түрү',
    update: 'Жаңылоо',
    comment: 'Комментарий',
    submit: 'Тастыктоо',
    applications: 'Арыздар',
    number_or_pin: '№/ИЖНи',
    applicant_address: 'Арыз берүүчүнүн дареги',
    address: 'Дареги',
    user_address_not_found: 'Колдонуучунун дареги табылган жок',
    child_citizenship: 'Туулгандыгы тууралуу күбөлүктүн берилген жери:',
    child_citizen_kg: 'Кыргыз Республикасы',
    child_citizen_foreign: 'Кыргыз Республикасынын чегинен тышкары',
    view: 'Көрүү',
    title: 'Аталышы',
    start_year: 'Окуу жылынын башталышы',
    end_year: 'Окуу жылынын аякташы',
    registration_by_residence_start: 'Жашаган жери боюнча каттоосунун башталышы',
    registration_by_residence_end: 'Жашаган жери боюнча каттоосунун аяктоосу',
    registration_outside_residence_start: 'Жашаган жеринен тышкаркы каттоосунун башталышы',
    registration_outside_residence_end: 'Жашаган жеринен тышкаркы каттоосунун аяктоосу',
    document_submission_start: 'Тастыктоонун жана документтерди берүүнүн башталышы',
    document_submission_end: 'Тастыктоонун жана документтерди берүүнүн аяктоосу',
    first_grade_enrollment_start: '1-класска киргизүүнүн башталышы',
    first_grade_enrollment_end: '1-класска киргизүүнүн аяктоосу',
    registration_deadline: 'Каттоонун аяктоосу',
    create: 'Түзүү',
    resource_created_successfully: 'Ресурс ийгиликтүү түзүлдү!',
    edit: 'Түзөтүү',
    add_applicant: 'Ата-эне маалыматын кошуу',
    deadline_info: 'Арыз берүү мөөнөтү',
    edit_deadline: 'Арыз берүү мөөнөтүн түзөтүү',
    resource_updated_successfully: 'Ресурс ийгиликтүү жаңыртылды!',
    deadlines: 'Арыз берүү мөөнөтү',
    delete: 'Жок кылуу',
    resource_deleted_successfully: 'Ресурс ийгиликтүү жок кылынды!',
    delete_submission_text: 'Чын эле бул ресурсту биротоло жок кылгыңыз келеби?',
    category: 'Категориясы',
    answer: 'Жооп',
    edit_faq: 'Суроо/жоопту түзөтүү',
    creation: 'Түзүү',
    position_quantity: 'Орундардын саны',
    quantity: 'Саны',
    groups: 'Топтор',
    editing: 'Түзөтүү',
    heading: 'Баш сөзү',
    attach_file: 'Файлды тиркөө',
    law: 'ЧУА',
    file: 'Файл',
    file_message: 'Документти PDF файл түрүндө тиркөө керек',
    legal_act: 'Ченемдик укуктук база',
    quota: 'Квота',
    quotas: 'Квоталар',
    user_info: 'Колдонуучунун маалыматтары',
    fullname: 'ФААсы',
    reset_password: 'Чыр сөздү тазалоо',
    users: 'Колдонуучулар',
    schools: 'Мектептер',
    preschools: 'Бала бакчалар',
    enrolled: 'Окууга киргизилгендер',
    waiting: 'Кезек күткөндөр',
    rejected: 'Четке кагылгандар',
    archiveds: 'Архивделгендер',
    students: 'Катталган балдардын тизмеси',
    transform: 'Которуу',
    student_info: 'Студенттин маалыматтары',
    enroll_date: 'Каттоо датасы',
    enrolled_to_first_grade: '1-класска жазылгандар',
    account_school: 'Мектептин жеке эсеби',
    account_preschool: 'Бала бакчанын жеке эсеби',
    auto_enrollment: 'Автокаттоо',
    auto_enrollment_on: 'Автоматтык каттоо режими иштетилди',
    auto_enrollment_off: 'Автоматтык каттоо режими өчүрүлдү',
    application_created_date: 'Арыз датасы',

    ordinary: 'Жеңилдиги жок',
    privileged: 'Жеңилдиктин негизинде',
    waiting_for_enroll_school: 'Жеңилдиктери боюнча кабыл алууну күткөндөр жана "Мыйзамдуу өкүл" катары тапшырылган өтүнмөлөр',

    free_quotas: 'Бош орундар',
    available_quotas: 'Бош орундар. <br> Жеңилдиги болгондор/ <br> Жеңилдиги болбогондор',
    total_quota: 'Жалпы квоталардын саны',
    total_ordinary_quota: 'Жеңилдиги болбогондор үчүн квоталардын саны',
    total_privilege_quota: 'Жеңилдиги болгондор үчүн квоталардын саны',
    total_enrolled_quota: 'Катталгандардын жалпы саны',
    total_ordinary_enrolled_quota: 'Жеңилдик аркылуу катталгандардын саны',
    total_privilege_enrolled_quota: 'Жеңилдиксиз катталгандардын саны',
    total_available_quota: 'Бош орундардын жалпы саны',
    total_ordinary_available_quota: 'Жеңилдиги болбогондор үчүн бош орундардын саны',
    total_privilege_available_quota: 'Жеңилдиги болгондор үчүн бош орундардын саны',

    student_successfully_archived: 'Студент ийгиликтүү архивделди!',
    raion: 'Район',
    aimak: 'Аймак',
    decree: 'Буйрук',
    print: 'Печать',
    employee_account: 'Кызматкердин жеке кабинети',
    parent_account: 'Ата-эненин жеке кабинети',
    inquiry: 'Маалымдама',
    motive: 'Мотив',
    in_process: 'Процесс иштелүүдө',

    address_not_found_error: 'Сиздин каттоо дарегиңиз табылган жок. Даректи тактоо үчүн жакынкы калкты тейлөө борборуна кайрылуу керек. <br>' +
        'Эгерде сиз үйдүн ээси болсоңуз, анда сиз төмөнкү дөкументтерди алып барыңыз:<br>' +
        '<br>' +
        'Паспорт;<br>' +
        'кыймылсыз мүлктүн техникалык паспорту.<br>' +
        'Эгерде сиз жашаган үйдүн ээси башка бирөө болсо, анда үйдүн ээси сизди жашаган дарегиңизге каттоого калкты тейлөө борборуна кошо барышы керек.<br>' +
        'Төмөнкү документтер суралат:<br>' +
        '<br>' +
        'Паспорт;<br>' +
        'мүлктүн ээсинин паспорту;<br>' +
        'кыймылсыз мүлктүн техникалык паспорту.',
    organization_not_found_error: 'Сиздин юридикалык каттоодо турган дарегиңиз базага кирбей калыптыр. Сиз катталган дарек биздин кызматкерлерге жөнөтүлдү. Алар тез арада сиздин маалыматты базага кошот. Бир аздан соң «Мектепти издөө» разделинен ПИН номериңизди жазып текшерсеңиз болот.',
    privileged_enrolled: 'Жеңилдик менен катталды',
    total_enrolled: 'Катталды',
    application_enrolled: 'Ийгиликтүү катталды',
    quota_empty: 'Орун калган жок',
    total_application_quantity: 'Арыздардын жалпы саны',
    waiting_for_enroll: 'Кезекти күткөндөр',
    waiting_for_enrolling: 'Каттоо күтүүдө',
    as_legal_representative: 'Мыйзамдуу өкүл катары',
    reports: 'Отчеттор',
    date_from: 'С',
    date_to: 'По',
    download: 'Жүктөп алуу',
    report_type: 'Отчет түрү',
    school_report: 'Отчет по ОО',
    preschool_report: 'Отчет по ДОО',
    school_report_by_district: 'Отчет по ОО по району',
    preschool_report_by_district: 'Отчет по ДОО по району',
    school_statistics_report: 'Сведения о количестве заявок в ОО по области',
    successfully_archived: 'Архивдөө ийгиликтүү аяктады',
    successfully_dropped: 'Чыгаруу ийгиликтүү аяктады',
    unsuccessfully_dropped: 'Чыгаруу ийгиликсиз аяктады',
    search: 'Издөө',
    transfer: 'Которуу',
    successfully_transfered: 'Ийгиликтүү которулду',
    unarchive: 'Архивден чыгаруу',
    successfully_unarchived: 'Архивден чыгаруу ийгиликтүү аяктады',
    transfer_students: 'Группанын студенттерин которуу',
    restore: 'Калыбына келтирүү',
    successfully_restored: 'Калыбына келтирүү ийгиликтүү аяктады',
    application_enrolled_date: 'Каттоо датасы',
    privilege: 'Жеңилдик',
    status_application: 'Арыздын статусу',
    check_application_status_school: 'Мектеп арызынын статусун текшерүү',
    check_application_status_preschool: 'Бала бакча арызынын статусун текшерүү',
    or: 'же',
    application_not_found: 'Арыз табылган жок',
    recheck_entered_data: 'Киргизилген маалыматтарды кайрадан текшергиле',
    return_back: 'Артка кайтуу',
    application_rejected: 'Арыз четке кагылды',
    application_dropped: 'Окуудан чыгарылды',
    attention: 'Көңүл бургула!',
    child_age: 'Баланын жашы',
    child_birth_year: 'Туулган жылы',
    selected_preschool: 'Тандалган бала бакча',
    selected_preschool_address: 'Бала бакчанын дареги:',
    status: 'Статус',
    application_waiting: 'Карап чыгуу күтүлүүдө',
    status_queue: 'Сиз :total орундун ичинен :queue-орундасыз',
    application_enroll: 'Кабыл алынган',
    status_enroll: 'Кабыл алынган',
    status_waiting: 'Кезек күтүүдө',
    status_rejected: 'Четке кагылды',
    status_archived: 'Архивделген',
    help: 'Жардам',
    certificate: 'Аттестат',
    content: 'Мазмун',
    content_type: 'Мазмун түрү',
    document: 'Документ',
    link: 'Шилтеме',
    must_be_filled: 'Толтурулушу керек',
    application_number: 'Арыздын номери',
    reset: 'Тазалоо',
    your_queue: 'Сиздин арызыңыз жалпы кезекте :queue-орунда турат',
    documents: 'Документтер',
    edit_privileges: 'Жеңилдиктерди түзөтүү',
    successfully_canceled: 'Арыз ийгиликтүү четке кагылды',
    to_cancel: 'Арызды жокко чыгаруу',
    cancel_confirm_text: 'Арызды чын эле жокко чыгаргыңыз келеби?',
    preschool_faq_description: 'Бала бакчага тапшырууда көп берилүүчү суроолорго жооптор',
    school_faq_description: 'Мектепке тапшырууда көп берилүүчү суроолорго жооптор',
    regulatory_legal_framework_description: 'Бул жерден сиз ченемдик укуктук базаны таба аласыз',
    frequently_asked_questions: 'Көп берилүүчү суроолор',
    video_description: 'Мамлекеттик жана муниципалдык мектептердин 1-классына электрондук каттоо',
    video_link: 'https://www.youtube.com/watch?v=HeOmoJx24rg',
    statistics_instruction: 'Бул жерден сиз жалпы квотанын саны, канча бала кабыл алынганы, бош орундар, канча бала жеңилдик менен кабыл алынды жана алардын канчасы текшерүүдө тургандыгы жөнүндө маалымат ала аласыз. Ал үчүн сиз мектеп же бала бакча бөлүмдөрүнүн бирин тандап, облусту, районду андан кийин мектепти же бала бакчаны тандашыңыз керек.',
    technical_support_all: '<h5>Техникалык колдоо:</h5>',
    application_waiting_director_confirm: 'Сиздин арыз директор тарабынан каралып жатат',
    rejected_time_text: 'Арыз :date, саат :time четке кагылды.',
    application_queue_caution_title: 'Учурда буйрутма маалыматын жаңылоо иштери жүрүп жатат. Күтө туруңуз, жакында сиз арызыңыздын абалын жана кезектеги номерди текшере аласыз. Түшүнгөнүңүз үчүн рахмат!',
    check_by_pin: 'ИЖН аркылуу текшерүү',
    check_by_birth_certificate: 'Туулгандыгы тууралуу күбөлүк аркылуу текшерүү',
    check_by_fio: 'Толук аты аркылуу текшерүү',
    application_school_successfully_changed: 'Өтүнмөнүн мектеби ийгиликтүү өзгөртүлдү!',
    application_preschool_successfully_changed: 'Өтүнмөнүн бала бакчасы ийгиликтүү өзгөртүлдү!',
    application_language_successfully_changed: 'Өтүнмөнүн окуу тили ийгиликтүү өзгөртүлдү!',
    warning: 'Эскертүү!!!',
    application_cancel_confirm_text: 'Урматтуу :fio, сиз берилген арызды кайра кайтарып алуудасыз :organization. Эгер макул болсоңуз, «Тастыктоо» баскычын басыңыз.',
    application_cancel_cancel_text: 'Арызды кайтарып алгандан кийин, бала ордун жоготот (кезек). Эгер макул эмес болсоңуз, «Жокко чыгаруу» баскычын басыңыз.',
    application_cancel_warning_text: 'Кийинчерээк, кайра тапшырганда, бардык маалыматтарды кайрадан толтуруу керек болот.',
    confirm: 'Тастыктоо',
    application_school_change_confirm_question: 'Чын эле мектепти алмаштыргыңыз келеби?',
    application_preschool_change_confirm_question: 'Чын эле бала бакчаны алмаштыргыңыз келеби? Бала бакчаны алмаштырууда кезекке турган орун жоголот',
    application_organization_language_change_confirm_question: 'Сиз чындап эле окутуу тилин өзгөрткүңүз келеби?',
    change: 'Өзгөртүү',
    wrong_email_note: 'Эгерде Сиз өтүнмөнү каттоодо электрондук почтаны туура эмес көрсөткөн болсоңуз, анда сиз КР ББИМнин жакынкы райондук билим берүү борборуна кайрылууңуз зарыл.',
    your_group: 'Сиздин кезек группаңыз',
    application_queue_note: 'Электрондук кезекте турган позицияңыз өзгөрүшү мүмкүн, анткени жөлөкпул талаптары биринчи кезекте каралып, кабыл алынат.',
    file_size_limit_validation: 'Файлдын максималдуу өлчөмү ашты (3 МБ)',
    waiting_enrolling: 'Кезеги келгендер',
    checked: 'Текшерүүдөн өткөндөр',
    expired: 'Убактысы өткөндөр',
    frozen: 'Токтоп тургандар',
    canceled: 'Өчүрүлгөндөр',
    dropped: 'Окуудан чыккандар',
    application_waiting_enrolling: 'Каттоо күтүүдө',
    auto_enrollment_confirm_question: 'Сиз чын эле иштеткиңиз келеби?',
    change_language: 'Окуу тилин өзгөртүү',
    enroll_comment_label: 'Кабыл алуу жөнүндө буйруктун датасын жана номерин көрсөтүү',
    restore_to_waiting: 'Күтүүдөгү кезектерге калыбына келтириңиз',
    restore_to_waiting_confirm_question: 'Сиз чын эле кезектерге калыбына келтириңиз келеби?',
    successfully_restored_to_waiting: 'Күтүүдөгү кезектерге ийгиликтүү калыбына келтирилди!',
    restore_to_waiting_enrolling: 'Күтүүдөгү катталууларга калыбына келтириңиз',
    restore_to_waiting_enrolling_confirm_question: 'Күтүүдөгү катталууларды чын эле калыбына келтиргиңиз келеби?',
    successfully_restored_to_waiting_enrolling: 'Күтүүдөгү каттоого ийгиликтүү калыбына келтирилди!',
    username_hint: 'Кирүү мисалы',
    document_brought: 'Документтер берилген',
    document_brought_on: 'Документтерди алып келгенин белгиледи',
    document_brought_off: 'Документтерди алып келгенин белгилеген жок',
    document_brought_confirm_question: 'Сиз чын эле документин алып келгенин тастыктайсызбы?',
    send_to_emis: 'ИСУО го жөнөтүү',
    sent_emis: 'ИСУО го жөнөтүлгөндөр',
    send_to_emis_title: 'Окууга кабыл алуу жөнүндө буйрук',
    send_to_emis_label: 'Жалпы билим берүү уюмунун 1-классына кабыл алуу жөнүндө',
    // emis_question: 'Приказ о зачислении. о принятии в 1 класс общеобразовательной организации',
    emis_yes: 'ИСУО го маалыматтарды жөнөтүү',
    emis_no: 'Жокко чыгаруу',
    from_message: '->',
    get_on_message: 'Кабыл алынды',

    statistic: {
        region_city: 'Облус/шаар',
        amount_preschool: 'МЧБУнун саны',
        amount_school: 'Мектептин саны',
        number_of_quota: 'Квоталар',
        number_of_enrolled_child: 'Окуган балдардын саны (катталгандар)',
        number_of_enrolled_child_in_the_school: ' Окууга киргизилгендер',
        number_of_free_places: 'Бош орундар',
        number_of_waiting_app: 'Арыздардын саны (кезек күткөндөр)',
        number_of_waiting_app_in_the_school: '"Мыйзамдуу өкүл" катары тапшырылган өтүнмөлөр',
        number_of_waiting_privileges_app: 'Алардын ичинен жеңилдиктери барлар',
        number_of_waiting_privileges_app_in_the_school: 'Жеңилдиктери боюнча кабыл алууну күткөндөр',
        district_city: 'Район/шаар',
        preschool_name: 'МЧББ мекемесинин аталышы',
        school_name: 'Мектептин аталышы',
    },
}