<template>
  <header class="header-border-bottom">
    <div class="header-sign-in">
      <div class="container-expand">
        <div class="header-contacts-wrapper">
          <div class="header-contacts">
            <p>{{ $lang.app.helplines }}</p>
            <div class="phone-icon-wrapper">
              <i class="bi-telephone-fill"></i>
            </div>
            <div>
              <p><b>110</b> <span class="red-text">{{ $lang.app.operating_mode }}</span></p>
            </div>
          </div>
        </div>
        <div class="header-top-right">
          <div class="header-sign-in-link-container">
            <router-link v-if="$store.getters.getUser" to="/admin" class="header-sign-in-link">
              <i class="bi-box-arrow-in-right"></i>{{ $lang.app.parent_account }}
            </router-link>
            <router-link v-else to="/auth/login-parent" class="header-sign-in-link">
              <i class="bi-box-arrow-in-right"></i>{{ $lang.app.parent_account }}
            </router-link>
            <router-link v-if="$store.getters.getUser" to="/admin" class="header-sign-in-link">
              <i class="bi-box-arrow-in-right"></i>{{ $lang.app.employee_account }}
            </router-link>
            <a v-else :href="ekyzmatLink" class="header-sign-in-link">
              <i class="bi-box-arrow-in-right"></i>{{ $lang.app.employee_account }}
            </a>
          </div>
          <div class="header-sign-in-languages">
            <a class="header-sign-in-lang-link" :class="{ 'active': $lang.$current === 'ru' }"
              @click.prevent="setLang('ru')">
              <span>{{ $lang.app.ru }}</span>
            </a>
            <a class="header-sign-in-lang-link" :class="{ 'active': $lang.$current === 'kg' }"
              @click.prevent="setLang('kg')">
              <span>{{ $lang.app.kg }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <nav class="main-nav">
      <div class="container-expand">
        <div class="main-nav-logo">
          <router-link to="/">
            <div class="header-logo-text" v-html="$lang.app.electronic_enroll"></div>
          </router-link>
        </div>
        <div class="main-nav-links-mobile">
          <button v-if="!mobileMenu" class="navBarBtn" @click="mobileMenu = true">
            <i class="bi-list"></i>
          </button>
          <button v-else class="navBarBtn" @click="mobileMenu = false">
            <i class="bi-x"></i>
          </button>
        </div>
        <div v-show="mobileMenu" class="main-nav-links-navbar-mobile" id="block">
          <ul>
            <li>
              <a class="header-sign-in-lang-link" :class="{ 'active': $lang.$current === 'ru' }"
                @click.prevent="setLang('ru')">
                <span>{{ $lang.app.ru }}</span>
              </a>
            </li>
            <li>
              <a class="header-sign-in-lang-link" :class="{ 'active': $lang.$current === 'kg' }"
                @click.prevent="setLang('kg')">
                <span>{{ $lang.app.kg }}</span>
              </a>
            </li>
            <li>
              <router-link to="/">{{ $lang.app.main }}</router-link>
            </li>
            <li>
              <div class="header-dropdown"
                :class="{ 'router-link-exact-active': $route.path === '/application-status' }">
                <span>{{ $lang.app.status_application }}</span>
                <div class="header-dropdown-content">
                  <!-- <div class="header-dropdown-item lg">
                    <router-link to="/application-status/school">{{ $lang.app.check_application_status_school
                      }}</router-link>
                  </div> -->
                  <div class="header-dropdown-item lg">
                    <router-link to="/application-status/preschool">{{ $lang.app.check_application_status_preschool
                      }}</router-link>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li>
              <router-link to="/organization-check">{{ $lang.app.organization_check }}</router-link>
            </li> -->
            <li>
              <div class="header-dropdown" :class="{ 'router-link-exact-active': $route.path === '/faq' }">
                <span>{{ $lang.app.faq }}</span>
                <div class="header-dropdown-content">
                  <!-- <div class="header-dropdown-item">
                    <router-link to="/faq?type=SCHOOL">{{ $lang.app.school }}</router-link>
                  </div> -->
                  <div class="header-dropdown-item">
                    <router-link to="/faq?type=PRESCHOOL">{{ $lang.app.preschool }}</router-link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <router-link to="/relationship-check">{{ $lang.app.relationship_check }}</router-link>
            </li>
            <li>
              <div class="header-dropdown">
                <span>{{ $lang.app.statistics }}</span>
                <div class="header-dropdown-content">
                  <!-- <div class="header-dropdown-item lg">
                    <router-link to="statistics/school-statistics">{{ $lang.app.school }}</router-link>
                  </div> -->
                  <div class="header-dropdown-item lg">
                    <router-link to="statistics/preschool-statistics">{{ $lang.app.preschool }}</router-link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <router-link v-if="$store.getters.getUser" to="/admin">
                <i class="bi-box-arrow-in-right link-icon"></i>
                <span>{{ $lang.app.parent_account }}</span>
              </router-link>
              <router-link v-else to="/auth/login-parent">
                <i class="bi-box-arrow-in-right link-icon"></i>
                <span>{{ $lang.app.parent_account }}</span>
              </router-link>
            </li>
            <li>
              <router-link v-if="$store.getters.getUser" to="/admin">
                <i class="bi-box-arrow-in-right link-icon"></i>
                <span>{{ $lang.app.login_account }}</span>
              </router-link>
              <a v-else :href="ekyzmatLink">
                <i class="bi-box-arrow-in-right link-icon"></i>
                <span>{{ $lang.app.employee_account }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="main-nav-links">
          <ul>
            <li class="main-nav-link main-nav-link-1">
              <router-link to="/">{{ $lang.app.main }}</router-link>
            </li>
            <li class="main-nav-link main-nav-link-1">
              <router-link to="/relationship-check">{{ $lang.app.relationship_check }}</router-link>
            </li>
            <!-- <li class="main-nav-link main-nav-link-1">
              <router-link to="/organization-check">{{ $lang.app.organization_check }}</router-link>
            </li> -->
            <li class="main-nav-link main-nav-link-2">
              <div class="header-dropdown">
                <span>{{ $lang.app.status_application }}</span>
                <div class="header-dropdown-content">
                  <!-- <div class="header-dropdown-item lg">
                    <router-link to="/application-status/school">{{ $lang.app.check_application_status_school
                      }}</router-link>
                  </div> -->
                  <div class="header-dropdown-item lg">
                    <router-link to="/application-status/preschool">{{ $lang.app.check_application_status_preschool
                      }}</router-link>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-nav-link main-nav-link-3">
              <div class="header-dropdown">
                <span>{{ $lang.app.statistics }}</span>
                <div class="header-dropdown-content">
                  <!-- <div class="header-dropdown-item lg">
                    <router-link to="statistics/school-statistics">{{ $lang.app.school }}</router-link>
                  </div> -->
                  <div class="header-dropdown-item lg">
                    <router-link to="statistics/preschool-statistics">{{ $lang.app.preschool }}</router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      mobileMenu: false,
      ekyzmatLink: null
    }
  },
  watch: {
    '$route'() {
      this.mobileMenu = false
    }
  },
  created() {
    // this.$axios.get('/auth-ekyzmat/link', {
    //   params: {
    //     state: 'backend-diamond',
    //     sender: process.env.NODE_ENV === 'development' ? 'local' : 'production',
    //   }
    // }).then(({data}) => {
    //   this.ekyzmatLink = data
    // })
    const linkekyzmat = 'https://auth.infocom.kg/oauth/authorize?client_id=enroll_edu&scope=pin&redirect_uri=https://mektep.edu.gov.kg/auth-ekyzmat/login&response_type=code&state=backend-diamond'
    this.ekyzmatLink = linkekyzmat
  },
  methods: {
    setLang(lang) {
      this.$lang.$setLang(lang)
      this.$emitter.emit('langChanged')
    }
  }
}
</script>
<style scoped>
.red-text {
  color: red;
}

.link-icon {
  margin-right: 8px;
}

.main-nav-links-navbar-mobile .router-link-exact-active {
  color: #4c9ac7;
}

.main-nav-links-navbar-mobile .header-dropdown .router-link-exact-active {
  color: #1f1f1f;
}

.header-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.header-dropdown .header-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.header-dropdown .header-dropdown-item {
  margin-bottom: 8px;
  line-height: 1.2;
}

.header-dropdown .header-dropdown-item.lg {
  width: 240px;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-border-bottom {
  border-bottom: 1px solid lightgray;
}

a {
  color: #1f1f1f;
  text-decoration: none;
}

.header-top-right {
  display: flex;
}

.header-sign-in {
  background: #e8e8e8;
}

.header-sign-in .container-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* . header-contacts-wrapper {
  display: flex;
  padding: 8px 0;
} */

.header-contacts-wrapper .header-contacts {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.header-contacts-wrapper .header-contacts p {
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 0;
  font-family: 'PT Sans', sans-serif;
}

.header-contacts-wrapper .header-contacts .phone-icon-wrapper {
  width: 36px;
  height: 36px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.header-sign-in-link-container {
  display: flex;
  align-items: center;
}

.header-sign-in-link {
  font-size: 14px;
  color: #1f1f1f;
  text-decoration: none;
  vertical-align: middle;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #888;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.header-sign-in-link i {
  vertical-align: middle;
  color: #888;
  font-size: 18px;
  margin-right: 10px;
}

.header-sign-in-languages {
  display: flex;
  height: 100%;
}

.header-sign-in-lang-link {
  cursor: pointer;
  margin-left: 30px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-sign-in-lang-link span {
  font-size: 14px;
}

.header-sign-in-lang-link.active span {
  color: #4c9ac7;
}

.main-nav .container-expand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  position: relative;
  z-index: 20;
  background: #fff;
}

.main-nav-logo {
  width: 38%;
}

.main-nav-logo div {
  width: 100%;
  height: 80px;
}

.header-logo-text {
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.main-nav-links-mobile {
  display: none;
}

.main-nav-links-navbar-mobile {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #dcdcdc;
  z-index: 50;
  transition: 1s;
}

.main-nav-links-navbar-mobile ul {
  padding: 35px 0 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}

.main-nav-links-navbar-mobile ul li {
  margin: 0 0 40px 0;
  padding: 0 45px;
  list-style: none;
  width: 50%;
  text-align: left;
}

.main-nav-links-navbar-mobile ul li .header-sign-in-lang-link {
  cursor: pointer;
  margin-left: 0;
  text-decoration: none;
  display: block;
  text-align: left;
}

.main-nav-links-navbar-mobile ul li a {
  color: #1f1f1f;
  text-decoration: none;
}

.main-nav-links {
  width: 62%;
}

.main-nav-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

.main-nav-links ul li {
  list-style: none;
  margin: 4px 0 4px 30px;
  padding: 0;
  display: flex;
  align-items: center;
}

.main-nav-links ul li a {
  color: #1f1f1f;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.main-nav-links ul li a:hover {
  border-bottom: 2px solid #4c9ac7;
}

.main-nav-links-navbar-mobile ul li .header-sign-in-lang-link span {
  font-size: inherit;
}

@media screen and (min-width: 1201px) and (max-width: 1235px) {
  .main-nav-links {
    width: 67%;
  }
}



@media screen and (max-width: 1200px) {
  .main-nav-links {
    display: none;
  }

  .header-top-right {
    display: none;
  }

  .header-sign-in-link {
    line-height: 1.2;
    font-size: 16px;
    color: #888;
    border-right: none;
  }

  .main-nav .container-expand {
    padding: 10px 15px;
  }

  .main-nav-links-mobile {
    display: flex;
  }

  .main-nav-links-mobile i {
    font-size: 36px;
  }

  .navBarBtn {
    background: transparent;
    border: none;
    color: #888;
  }

  .main-nav-links-mobile i {
    font-size: 36px;
  }

  .main-nav-link-1 {
    order: 1;
  }

  .main-nav-link-2 {
    order: 4;
  }

  .main-nav-link-3 {
    order: 2;
  }

  .main-nav-logo {
    width: 65%;
  }
}

@media screen and (max-width: 767px) {
  .header-logo-text {
    font-size: 22px;
  }

  .header-sign-in-link {
    padding-right: 20px;
  }

  .header-sign-in-link i {
    margin-right: 0;
  }

  .main-nav-links-navbar-mobile ul li {
    width: 100%;
  }

  .main-nav-link-2 {
    order: 3;
  }

  .main-nav-link-3 {
    order: 5;
  }

  .header-sign-in-link span {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .header-logo-text {
    font-size: 20px;
  }

  .main-nav-logo div {
    width: 285px;
  }

  .header-sign-in-link {
    padding-right: 0;
  }

  .navBarBtn {
    padding: 0 3px;
  }

  .main-nav-links-mobile i,
  .main-nav-links-mobile i {
    font-size: 24px;
  }

  .main-nav-links-navbar-mobile ul li {
    padding: 0 0 0 40px;
  }

  .main-nav-link-2 {
    order: 2;
  }

  .main-nav-link-3 {
    order: 3;
  }

  .main-nav-links-mobile {
    flex-direction: column;
  }
}
</style>